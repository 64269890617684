import { AlertResponseType, AlertsSettingsResponseType, AuthenticatedClient } from '_api';
import { AxiosResponse } from 'axios';

export const getAlerts = (userId: string , lang: string, pageSize?: number, lastId?: string): Promise<AxiosResponse<AlertResponseType[]>> => {
  const paramPageSize = pageSize ? `&pageSize=${pageSize}` : '';
  const paramLastId = lastId ? `&lastId=${lastId}` : '';
  return AuthenticatedClient.get(`/alerts/?lang=${lang}&userId=${userId}` + paramPageSize + paramLastId);
};

export const countAlerts = (userId: string , lang :string): Promise<AxiosResponse<AlertResponseType[]>> => {
  return AuthenticatedClient.get(`/alerts/count/?lang=${lang}&userId=${userId}` );
};

export const markAlertsAsRead = (userId: string, alerts: string[]): Promise<AxiosResponse<void>> => {
  return AuthenticatedClient.put('/putAlerts/' + userId, {
    command: "read",
    ids: alerts
  });
};

export const markAlertsAsDelete = (userId: string, alerts: string[]): Promise<AxiosResponse<void>> => {
  return AuthenticatedClient.put('/putAlerts/' + userId, {
    command: "delete",
    ids: alerts
  });
};

export const getAlertsSettings = (userId: string, role: string, email: string) : Promise<AxiosResponse<AlertsSettingsResponseType>> => {
  return AuthenticatedClient.get(`/alerts-settings/?userId=${userId}&role=${role}&email=${email}`);
};

export const updateAlertSetting = (userId: string, settingId: string, type: 'trigano' | 'email', value: boolean) => {
  const params: any = {};
  params[type] = value;
  return AuthenticatedClient.put(`/putAlertsSettings/${userId}?settingId=${settingId}`, params);
};
