import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/img/trigano-header.svg';
import {
	Button,
	ButtonType,
	OptionSelect,
	Searchbar,
	HeaderDropdown,
} from '_components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
	atomDealers,
	atomToken,
	atomUser,
	atomCOC,
	atomDealersBrand,
	atomUserRoles,
	atomUserRole,
	atomAlertsCountDisplay,
} from '_atoms';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DealershipResponseType, countAlerts, getDealershipsById } from '_api';
import { AxiosResponse } from 'axios';
import './header.scss';
import { useLoading } from '_hooks';
import { toast } from 'react-toastify';

type SearchFormValues = {
	search: string;
};

const Header: FunctionComponent = () => {
	const [token] = useRecoilState(atomToken);
	const setAtomDealers = useSetRecoilState(atomDealers);
	const setAtomDealersBrands = useSetRecoilState(atomDealersBrand);
	const setAtomCOC = useSetRecoilState(atomCOC);
	const [subMenuOpened, setSubMenuOpened] = useState(false);
	const { t } = useTranslation();
	const isConnected = !!token;
	const navigate = useNavigate();
	const [activeRoute, setActiveRoute] = useState<string>();
	const userLogged = useRecoilValue(atomUser);
	const userRoles = useRecoilValue(atomUserRoles);
	const [dealershipOptions, setDealershipOptions] = useState<
		Array<OptionSelect>
	>([]);
	const [dealershipSelectedOptions, setDealershipSelectedOptions] = useState<
		Array<OptionSelect>
	>([]);
	const [dealersRequestLoading, setDealersRequestLoading] =
		useState<boolean>(false);
	const { setLoading } = useLoading();
	const [alertsDisplayNumber, setAlertsDisplayNumber] = useRecoilState(
		atomAlertsCountDisplay
	);

	const { i18n } = useTranslation();

	const loadAlertsCount = () => {
		if (userLogged && userLogged.id) {
			setLoading(true);
      // Count unread alerts
			countAlerts(userLogged.id, i18n.language).then(
				(result) => {
          // Set unread alerts number in notifications button
					setAlertsDisplayNumber(Number(result.data));
				},
				() => {
					toast.error(
						'Une erreur est survenue lors de la récupération des données'
					);
					setLoading(false);
				}
			);
		}
	};
  // Update number whenever state changes
	useEffect(() => {
		loadAlertsCount();
	}, [atomAlertsCountDisplay]);

	const searchValidationSchema = Yup.object().shape({
		search: Yup.string().required(),
	});
	const [userRole] = useRecoilState(atomUserRole);

	const userBrand = userRoles?.brands;

	const brandNames = userBrand.map((brand: any) => brand.name);

	const isAgent = userRole?.name === 'Agent';
	const isAgentLimite = userRole?.name === 'Agent-Limite';
	const isUserTrigano = userRole?.name === 'User-trigano';
	const isDAF = userRole?.name === 'DAF';
	const isADV = userRole?.name === 'ADV';
	const isCommercial = userRole?.name === 'Manager_commercial';
	const isCommercialLimite = userRole?.name === 'Commercial';

	const searchOnSubmit = (data: SearchFormValues) => {
		navigate('/general-search?q=' + data.search);
	};

	const onDealershipsChange = (data: any[]) => {
		setAtomDealers(data.map((dealer) => dealer.value));
		setAtomDealersBrands(data);
		setDealershipSelectedOptions(data);
		const dealerObjet = data.map((option) => {
			return { name: option.name, CUNO: option.CUNO };
		});
		setAtomCOC(dealerObjet);
		setDealershipSelectedOptions(data);
	};

	const { control: searchControl, handleSubmit: searchHandleSubmit } =
		useForm<SearchFormValues>({
			resolver: yupResolver(searchValidationSchema),
			mode: 'onChange',
			defaultValues: {
				search: '',
			},
		});

	useEffect(() => {
		setActiveRoute(window.location.pathname);
	}, [navigate]);

	useEffect(() => {
		// Load dealerships.
		if (userLogged && userLogged.username) {
			setDealersRequestLoading(true);
			getDealershipsById(userLogged.username)
				.then((result: AxiosResponse<DealershipResponseType[]>) => {
					setDealersRequestLoading(false);
					const dealers = result.data.map((dealer) => {
						return {
							label: dealer.name,
							value: dealer.id.toString(),
							name: dealer.name,
							CUNO: dealer.CUNO,
							ADID: dealer.ADID,
							brands: dealer.brands.map((brand) => ({
								id: brand.id,
								name: brand.name,
							})),
						};
					});
					const filteredDealers = dealers.filter((dealer) => {
						let found = false;
						dealer.brands.forEach((brand) => {
							if (brandNames.includes(brand.name)) {
								found = true;
							}
						});
						return found;
					});

					const filteredDealerIds = filteredDealers.map(
						(dealer: any) => dealer.value
					);

					const filteredOptions = filteredDealers.map((dealerId: any) => ({
						label: dealerId.label, // Utilisez une valeur significative ici si possible
						value: dealerId.value,
						name: dealerId.name,
						CUNO: dealerId.CUNO,
					}));

					// Mise à jour des options en fonction de la condition isAgent ou isUser
					if (isAgent || isAgentLimite || isUserTrigano) {
						setDealershipOptions(filteredOptions);
						setDealershipSelectedOptions(filteredOptions);
						setAtomDealers(filteredDealerIds);
					} else {
						setDealershipOptions(dealers);
						setDealershipSelectedOptions(dealers);
						setAtomDealers(dealers.map((dealer) => dealer.value));
						setAtomDealersBrands(dealers);
					}
					const dealerObjet = dealers.map((option) => {
						return { name: option.name, CUNO: option.CUNO };
					});

					const dealerObjetFilter = filteredOptions.map((option) => {
						return { name: option.name, CUNO: option.CUNO };
					});

					if (isAgent || isAgentLimite || isUserTrigano) {
						setAtomCOC(dealerObjetFilter);
					} else {
						setAtomCOC(dealerObjet);
					}
				})
				.catch((error) => {
					setDealersRequestLoading(false);
					if (error.response) {
						switch (error.response.status) {
							case 412:
								toast.error(
									t('toast_error_retrieving_dealer_ids_linked_to_the_user')
								);
								break;
							case 413:
								toast.error(
									t(
										'toast_error_retrieving_dealer_information_linked_to_the_user'
									)
								);
								break;
							case 414:
								toast.error(
									t(
										'toast_error_when_retrieving_brands_associated_with_dealers_on_the_pim'
									)
								);
								break;
							default:
								toast.error(t('toast_get_data_error'));
						}
					} else {
						toast.error(t('toast_get_data_error'));
					}
				});
		}
	}, []);

	const getNavClass = (route: string) => {
		if (
			(activeRoute &&
				activeRoute?.indexOf('account') > -1 &&
				route === 'home') ||
			(activeRoute && activeRoute?.indexOf(route) > -1)
		) {
			return 'header__navigation-link header__navigation-link--active';
		} else if (
			activeRoute &&
			activeRoute?.indexOf('planning') > -1 &&
			route === 'search'
		) {
			return 'header__navigation-link header__navigation-link--active';
		} else {
			return 'header__navigation-link';
		}
	};

	return (
		<header className='header'>
			<div className='header__content-wrapper'>
				<Link to='/search' className='header__logo-link'>
					<Logo className='header__logo' />
				</Link>
				<div className='header__actions-wrapper'>
					{isConnected ? (
						<>
							<form
								className='header__searchbar-wrapper'
								onSubmit={searchHandleSubmit(searchOnSubmit)}
							>
								<Searchbar
									placeholder={t('header_search')}
									control={searchControl}
									onSubmit={searchHandleSubmit(searchOnSubmit)}
								/>
							</form>
							<form className='header__dealership-filter-wrapper'>
								<MultiSelect
									options={dealershipOptions}
									valueRenderer={(selected, _options) => {
										if (selected.length === _options.length) {
											return t('header_dealerships');
										} else if (selected.length === 0) {
											return t('header_no_dealership');
										} else if (selected.length > 1) {
											return (
												selected.length + ' ' + t('header_dealerships_selected')
											);
										} else {
											return selected.map(({ label }) => label + ' ');
										}
									}}
									value={dealershipSelectedOptions}
									onChange={onDealershipsChange}
									isLoading={dealersRequestLoading}
									labelledBy={t('search')}
									overrideStrings={{
										allItemsAreSelected: t('header_dealerships_selected'),
										clearSearch: t('header_clear_search'),
										clearSelected: t('header_clear_selected'),
										noOptions: t('header_no_dealer'),
										search: t('search'),
										selectAll: t('header_select_all_dealers'),
										selectAllFiltered: t('header_select_all_dealers_filtered'),
										selectSomeItems: t('header_select_dealer'),
										create: t('created'),
									}}
								/>
							</form>
							<Button
								type={ButtonType.ALERT}
								alertsNb={alertsDisplayNumber > 21 ? 20 : alertsDisplayNumber}
								onClick={() => navigate('/alerts/all')}
							/>
							<HeaderDropdown />
						</>
					) : (
						<Button
							type={ButtonType.LIGHTSMALL}
							text={t('login_button')}
							onClick={() => navigate('login')}
						/>
					)}
				</div>
			</div>

			<div className='header__navigation-wrapper'>
				<nav className='header__navigation'>
					<NavLink to='/search' className={getNavClass('search')}>
						{t('header_nav_search')}
					</NavLink>
					<NavLink to='/quotation' className={getNavClass('quotation')}>
						{t('header_nav_quotation')}
					</NavLink>
					<NavLink to='/stock' className={getNavClass('stock')}>
						{t('header_nav_stock')}
					</NavLink>
					<NavLink to='/orders' className={getNavClass('orders')}>
						{t('header_nav_orders')}
					</NavLink>
					<div
						className={`header__navigation-multi-items ${
							subMenuOpened ? 'header__navigation-multi-items--opened' : ''
						}`}
						onMouseEnter={() => setSubMenuOpened(true)}
						onMouseLeave={() => setSubMenuOpened(false)}
					>
						<button className='header__navigation-multi-items-text'>
							{t('header_nav_dealership')}
						</button>
						<div className='header__navigation-multi-items-list'>
							{!(isCommercialLimite || isCommercial || isADV) ? (
								<NavLink
									to='/account-statement'
									className='header__navigation-multi-items-list-item'
								>
									{t('header_nav_account_statement')}
								</NavLink>
							) : (
								<div className='header__navigation-multi-items-list-item disabled'>
									{t('header_nav_account_statement')}
								</div>
							)}
							{!isDAF ? (
								<NavLink
									to='/conformity-certificate'
									className='header__navigation-multi-items-list-item'
								>
									{t('header_nav_conformity_certificate')}
								</NavLink>
							) : (
								<div className='header__navigation-multi-items-list-item disabled'>
									{t('header_nav_conformity_certificate')}
								</div>
							)}
						</div>
					</div>
					<NavLink to='/exchange' className={getNavClass('exchange')}>
						{t('header_nav_exchange')}
					</NavLink>
				</nav>
			</div>
		</header>
	);
};

export default Header;
