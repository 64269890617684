import {
  StockResponseType,
  getStock,
  Stock,
  InfoClient,
  updateStock,
  validateStockExchange,
  point_of_sale,
  updatePointOfSale,
} from "_api";
import {
  ClientInformations,
  Modal,
  PageTitle,
  PointOfSaleModalStock,
  StockExchangeSharing,
  StockTable,
} from "_components";
import { useLoading, useModal } from "_hooks";
import { AxiosError, AxiosResponse } from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./stock-page.scss";
import { atomDealers } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { atomUser } from "_atoms";
import { Pagination } from "_types";

const PAGINATION_STEP = 20;

const StockPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setModal } = useModal();
  const [stocks, setStocks] = useState<Stock[]>([]);
  const { loading, setLoading } = useLoading();
  const [dealers] = useRecoilState(atomDealers);
  const user = useRecoilValue(atomUser);
  const [selectedCUNO, setSelectedCUNO] = useState<string>("");
  const [isStockModal, setIsStockModal] = useState(false);
  const [isClientInfoModal, SetIsClientInfoModal] = useState(false);
  const [isPointOfSaleModal, setIsPointOfSaleModal] = useState(false);
  const [currentORNO, setCurrentORNO] = useState<string | null>(null);
  const [currentPointOfSaleId, setCurrentPointOfSaleId] =
    useState<string | null>(null);
  const [stockExchangeCommentary, setStockExchangeCommentary] =
    useState<string | null>(null);
  const [pagination, setPagination] = useState<Pagination>({
    skip: 0,
    top: PAGINATION_STEP,
  });
  const [hasMore, setHasMore] = useState(true);

  const handleSelectedCUNOChange = (newCUNO: string) => {
    setSelectedCUNO(newCUNO);
  };

  const initData = async () => {
    try {
      const stockResult: AxiosResponse<StockResponseType> = await getStock(
        dealers,
        user?.country,
        pagination.skip,
        pagination.top
      );
      if (stockResult.data.stock.length === 0) {
        if (stocks.length === 0) {
          // No results at all
          toast.info(t("toast_no_stock_data"));
        } else {
          // We've reached the end of the available data
          setHasMore(false);
          toast.info(t("toast_no_more_results"));
        }
      } else {
        setStocks((prevStocks) => [...prevStocks, ...stockResult.data.stock]);
      }
    } catch (error: unknown) {
      setHasMore(false);
      if (error instanceof AxiosError) {
        if (error.response?.status !== 412) {
          toast.error(t("toast_get_data_error"));
        } else {
          toast.error(t("toast_no_more_results"));
        }
      } else {
        toast.error(t("toast_unknown_error"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenStockModal = (stock: Stock | null) => {
    if (stock !== null) {
      setIsStockModal(true);
      setCurrentPointOfSaleId(stock.point_of_sale.id);
      setCurrentORNO(stock.ORNO);
    } else {
      setModal(false);
      setIsStockModal(false);
    }
  };

  const handleOpenPointOfSaleModal = (stock: Stock | null) => {
    if (stock !== null) {
      setModal(true);
      setIsPointOfSaleModal(true);
      setCurrentPointOfSaleId(stock.ORNO);
    } else {
      setModal(false);
      setIsPointOfSaleModal(false);
      setCurrentPointOfSaleId(null);
    }
  };

  const handleOpenClientInfoModal = (stock: Stock | null) => {
    if (stock !== null) {
      setModal(true);
      SetIsClientInfoModal(true);
      setCurrentORNO(stock.ORNO);
    } else {
      setModal(false);
      SetIsClientInfoModal(false);
    }
  };

  const handleCancel = (orno: string) => {
    setStocks(
      stocks.map((stock) => {
        if (stock.ORNO === orno) {
          stock.stockExchange = false;
        }
        return stock;
      })
    );
  };

  const handleClientInfoChange = (infoClient: InfoClient) => {
    setLoading(true);
    if (infoClient) {
      const filteredStocks = stocks.find((stock) => stock.ORNO === currentORNO);
      if (filteredStocks && user) {
        updateStock(
          filteredStocks.ORNO,
          filteredStocks.CUNO,
          infoClient,
          user,
          null,
          false
        ).then(
          () => {
            initData();
            toast.success(t("client_add_success"));
          },
          () => {
            setLoading(false);
            toast.error(t("toast_get_data_error"));
          }
        );
      }
    }
  };

  const handleStockExchangeSharingChange = (StockExchange: string) => {
    setStockExchangeCommentary(StockExchange);
  };

  const onStockExchangeSharingSubmit = () => {
    const stockFound = stocks.find((stock: Stock) => {
      return stock.ORNO == currentORNO;
    });

    if (stockFound) {
      setLoading(true);
      validateStockExchange(
        stockFound,
        dealers,
        stockExchangeCommentary,
        user
      ).then(
        () => {
          initData();
          toast.success(t("exchange_success"));
        },
        (error) => {
          setLoading(false);
          console.log("error: ", error);
        }
      );
    }
  };

  const handleSelectedOptionChange = (
    point_of_sale: point_of_sale | undefined
  ) => {
    setLoading(true);
    if (point_of_sale) {
      const filteredStocks = stocks.find(
        (stock: Stock) => stock.ORNO === currentPointOfSaleId
      );

      const selectedORNO = filteredStocks?.ORNO;
      const selectedADID = point_of_sale.cunoAdid;

      if (filteredStocks) {
        updatePointOfSale(selectedORNO, selectedADID, point_of_sale)
          .then(() => {
            initData();
            toast.success(t("point_of_sale_update_success"));
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response) {
              switch (error.response.status) {
                case 412:
                  toast.error(t("toast_error_updating_point_of_sale_on_azure"));
                  break;
                case 413:
                  toast.error(t("toast_error_updating_point_of_sale_on_m3"));
                  break;
                default:
                  toast.error(t("toast_get_data_error"));
              }
            } else {
              toast.error(t("toast_get_data_error"));
            }
          });
      } else {
        setLoading(false);
        toast.error(t("toast_get_data_error"));
      }
    } else {
      setLoading(false);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      // We're at the bottom or very close to it
      onLoadMore();
    }
  };
  
  // On load more => update pagination.
  const onLoadMore = () => {
    if (hasMore && !loading) {
      setLoading(true);
      setPagination((prev) => ({
        skip: prev.skip + PAGINATION_STEP,
        top: PAGINATION_STEP,
      }));
    }
  };

  useEffect(() => {
    if (dealers && dealers.length) {
      initData();
    }
  }, [dealers, user, pagination]);

  return (
    <div className="stocks">
      <PageTitle
        title={t("my_vehicle_stock_title")}
        description={t("my_vehicle_stock_description")}
      />
      <div className="stocks__main-wrapper">
        {stocks && stocks.length === 0 ? (
          <p className="no-data">{t("")}</p>
        ) : null}

        {stocks && stocks.length ? (
          <StockTable
            setClientInfoModal={handleOpenClientInfoModal}
            setPointOfSaleModal={handleOpenPointOfSaleModal}
            setStockInfoModal={handleOpenStockModal}
            stockProducts={stocks}
            onSelectedCUNOChange={handleSelectedCUNOChange}
            onScroll={handleScroll}
          />
        ) : null}
        {isClientInfoModal && (
          <Modal
            content={true}
            oncancel={() => SetIsClientInfoModal(false)}
            onValidate={() => SetIsClientInfoModal(false)}
          >
            <ClientInformations
              title={t("add_order_client")}
              name="clientInfo"
              onClose={() => SetIsClientInfoModal(false)}
              onClientChange={handleClientInfoChange}
            />
          </Modal>
        )}
        {isPointOfSaleModal && (
          <Modal
            content={true}
            oncancel={() => setIsPointOfSaleModal(false)}
            onValidate={() => setIsPointOfSaleModal(false)}
          >
            <PointOfSaleModalStock
              name="PointOfSale"
              onClose={() => setIsPointOfSaleModal(false)}
              selectedCUNO={selectedCUNO}
              onSelectedOptionChange={handleSelectedOptionChange}
            />
          </Modal>
        )}
        {isStockModal && (
          <Modal
            content={true}
            oncancel={() => {
              setIsStockModal(false);
            }}
            onValidate={() => {
              setIsStockModal(false);
            }}
          >
            <StockExchangeSharing
              onSubmit={onStockExchangeSharingSubmit}
              onCancel={handleCancel}
              onClose={() => {
                setIsStockModal(false);
              }}
              onStockExchangeSharingChange={handleStockExchangeSharingChange}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default StockPage;
