import {
  BaseSyntheticEvent,
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  AlertResponseType,
  getAlerts,
  markAlertsAsDelete,
  markAlertsAsRead,
} from '_api';
import { LocalLoader } from '_components';
import { atomAlertsCount, atomUser, atomAlertsCountDisplay } from '_atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLoading } from '_hooks';
import { toast } from 'react-toastify';
import './alerts-page.scss';

const AlertsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>([]);
  const [alerts, setAlerts] = useState<AlertResponseType[]>([]);
  const [filteredAlerts, setFilteredAlerts] = useState(alerts);
  const userLogged = useRecoilValue(atomUser);
  const [currentCategory, setCurrentCategory] = useState<string>('all');
  const { setLoading } = useLoading();
  const setAlertsCount = useSetRecoilState(atomAlertsCount);
  const setAlertsCountDisplay = useSetRecoilState(atomAlertsCountDisplay);
  const { i18n } = useTranslation();
  const [lastAlertId, setLastAlertId] = useState<string>('');
  const [hasMoreAlerts, setHasMoreAlerts] = useState<boolean>(false);
  const numberOfAlertsToFetch = 20;
  
  const loadAlerts = () => {
    if (userLogged && userLogged.id) {
      setLoading(true);
      getAlerts(userLogged.id, i18n.language).then(
        (result) => {
          const filteredAlerts = result.data.filter(
            (alert) => alert.onTriganoPro === 'true'
          );
          const filteredReadAlerts = filteredAlerts.filter(
            (alert) => alert.read === 'false'
          );

          // Update unread alerts in header
          const countAlerts = filteredReadAlerts.length;
          setAlertsCountDisplay(countAlerts);
          setAlertsCount(countAlerts);
          // Set all alerts for the page based on selected filter.
          setAlerts(filteredAlerts);
          setFilteredAlerts(filteredAlerts);
          // Set state for initial page load to check for more
          // alerts. If initial page load has zero results we
          // don't use infinite scroll.
          if (result.data.length === 0) {
            setHasMoreAlerts(false);
          } else {
            setHasMoreAlerts(true);
          }
          // Get last id of alert for api request
          const lastId = filteredAlerts[filteredAlerts.length - 1];
          setLastAlertId(lastId?.id);       
          // }
          // Turn off loading icon
          setLoading(false);

        },
        (error) => {
          console.log('error: ', error);
          toast.error(
            'Une erreur est survenue lors de la récupération des données'
          );
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    loadAlerts();
  }, [setAlertsCount]);

  const handleCheckAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setChecked(alerts.map((alert) => alert.id));
    } else {
      setChecked([]);
    }
  };

  const handleAlertChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setChecked([...checked, id]);
    } else {
      setChecked(checked.filter((item) => item !== id));
    }
  };

  // Categories filter
  const filterCategoryAlerts = (category: string) => {
    if (category === 'all') return alerts;
    else {
      const filteredAlerts = alerts.filter((type) => type.level === category);
      return filteredAlerts;
    }
  };

  useEffect(() => {
    setFilteredAlerts(filteredAlerts);
  }, [filteredAlerts]);

  const handleCategoryClick = (e: BaseSyntheticEvent) => {
    const typeAlert = e.target.value;
    typeAlert !== 'all'
      ? setFilteredAlerts(filterCategoryAlerts(typeAlert))
      : setFilteredAlerts(alerts);
    setCurrentCategory(typeAlert);
  };

  // Delete all selected rows
  const handleRemove = () => {
    handleAlertChange;
    const toDelete: Array<string> = [];

    // if I have selected rows
    if (checked && checked.length > 0) {
      checked.map((alertChecked) => {
        // if my row exists and not empty
        if (alertChecked && alertChecked !== '') {
          toDelete.push(alertChecked);
        }
      });
    }

    // if I'm logged and there is rows to delete
    if (userLogged && toDelete.length > 0) {
      setLoading(true);
      markAlertsAsDelete(userLogged.id, toDelete).then(
        () => {
          setLoading(false);
          loadAlerts();
        },
        (error) => {
          console.log('error: ', error);
          setLoading(false);
          toast.error(
            'Une erreur est survenue lors de la modification des données'
          );
          loadAlerts();
        }
      );
    }
  };

  // Mark as read all selected rows
  const handleMarkedAsRead = () => {
    const toMarkedAsRead: Array<string> = [];

    // if I have selected rows
    if (checked && checked.length > 0) {
      checked.map((alertChecked) => {
        // if my row exists and not empty
        if (alertChecked && alertChecked !== '')
          toMarkedAsRead.push(alertChecked);
      });
    }

    if (userLogged && toMarkedAsRead.length) {
      setLoading(true);
      markAlertsAsRead(userLogged.id, toMarkedAsRead).then(
        () => {
          setLoading(false);
          loadAlerts();
        },
        (error) => {
          console.log('error: ', error);
          setLoading(false);
          loadAlerts();
          toast.error(
            'Une erreur est survenue lors de la modification des données'
          );
        }
      );
    }
  };

  // On load more => update pagination.
  const onLoadMore = () => {
    if (userLogged && userLogged.id) {
      getAlerts(userLogged.id, i18n.language, numberOfAlertsToFetch, lastAlertId).then(
        (results) => {
          // If we send the api the last id and it returns results,
          // we add the new results to the former results and we
          // tell the infinite scroll there is more to load.
          // If we send the api the last id and it's empty,
          // then there are no further results and we don't
          // need the infinite scroll.
          if (results.data.length) {
            // Always set with selected filter type.
            const nextAlerts = results.data.filter(
              (alert) => alert.onTriganoPro === 'true'
            );
            setAlerts((prevAlerts) => [...prevAlerts, ...nextAlerts]);

            const lastId = nextAlerts[nextAlerts.length - 1];
            setLastAlertId(lastId?.id);

            setHasMoreAlerts(true);
          } else {
            setHasMoreAlerts(false);
          }
        },
        (error) => {
          console.log('error: ', error);
          toast.error(
            'Une erreur est survenue lors de la récupération des données'
          );
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className='alerts-page'>
      <div className='alerts-page__header'>
        <input
          type='checkbox'
          className='alerts-page__checkbox'
          checked={checked.length === alerts.length}
          onChange={handleCheckAllChange}
        />
        <button
          className={`alerts-page__category ${
            currentCategory === 'all' ? 'alerts-page__category--selected' : ''
          }`}
          value='all'
          onClick={handleCategoryClick}
        >
          {t('all')}
          <span> ({alerts.length})</span>
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === 'urgent'
              ? 'alerts-page__category--selected'
              : ''
          }`}
          value='urgent'
          onClick={handleCategoryClick}
        >
          <span className='dot --warning'></span>
          {t('urgents')}
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === 'notification'
              ? 'alerts-page__category--selected'
              : ''
          }`}
          value='notification'
          onClick={handleCategoryClick}
        >
          <span className='dot --info'></span>
          {t('notifications')}
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === 'alerte'
              ? 'alerts-page__category--selected'
              : ''
          }`}
          value='alerte'
          onClick={handleCategoryClick}
        >
          <span className='dot --my-alerts'></span>
          {t('my_alerts')}
        </button>
        <button
          className='alerts-page__action read'
          onClick={handleMarkedAsRead}
        >
          {t('alerts_page_mark_as_read')}
        </button>
        <button className='alerts-page__action delete' onClick={handleRemove}>
          {t('alerts_page_delete')}
        </button>
      </div>
      <div id="alerts-page-body" className='alerts-page__body'>
        <InfiniteScroll
          dataLength={(alerts && alerts.length) || 0}
          hasMore={hasMoreAlerts}
          next={onLoadMore}
          loader={<LocalLoader />}
          className='search__scroll-container'
          scrollableTarget='alerts-page-body'
        >
          {alerts.map((alert) => (
            <div
              className={`alerts-page__line ` + `${alert.read}`}
              key={alert.id}
            >
              <div
                className={`alerts-page__line-category ${alert.level}`}
              ></div>
              <input
                type='checkbox'
                className='alerts-page__checkbox'
                id={alert.id}
                checked={checked.includes(alert.id)}
                onChange={(e) => handleAlertChange(e, alert.id)}
              />
              <div className='alerts-page__line-message'>
                {alert.category === 'mes alertes' ? (
                  <div className='alerts-page__line-text'>
                    {alert.messageSite}
                    <span> "{alert.model}"</span>
                  </div>
                ) : (
                  <div className='alerts-page__line-text'>
                    {alert.messageSite}
                    <span> {alert.orderNumber}</span>
                    <span className='alerts-page__line-text--color'>
                      {' '}
                      {alert.orderStatus}{' '}
                    </span>
                  </div>
                )}
                <div className='alerts-page__line-info'>
                  <span className='alerts-page__line-info--date'>
                    {alert.date
                      ? new Date(alert.date).toLocaleDateString()
                      : ''}
                  </span>
                  {alert.read === 'false' ? (
                    <span className='alerts-page__line-info--unread'>
                      {' '}
                      {t('unread')}{' '}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default AlertsPage;
